import { down } from 'styled-breakpoints';
import styled from 'styled-components';
import { Button } from 'components/utilities/components/Button';
import { ModalButton } from 'components/utilities/components/ModalButton';
import { Tag } from 'components/utilities/components/SingleTag';
import { theme } from 'components/utilities/utils';

const Stream = styled.div`
  height: fit-content;
  background-color: ${(props) => props.theme.colors.white};
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  flex: 1;
  z-index: 2;
  position: relative;
  border-radius: 0px;
  padding: 0px;
  min-width: 280px;
  @media screen and (max-width: 1000px) {
    width: 100%;
  }
  // ${down('md')} {
  background-color: ${(props) => props.theme.colors.white};
  box-shadow: 0 0px 15px ${(props) => props.theme.colors.white21};
  border-radius: 0px 0px 5px 5px;
  box-sizing: border-box;
  padding: 20px;
  margin: 0px 15px 0px 0px;
  // }
`;

const StreamImage = styled.img`
  display: flex;
  width: 100%;
  border-radius: 0px;
  ${down('md')} {
  }
  &.short {
    width: 180px;
  }
`;

const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 5px;
  ${down('md')} {
  }
`;

const ProgramTag = styled(Tag)`
  margin-right: 5px;
  margin-bottom: 10px;
  margin-top: 0px;
  font-size: 12px;
  font-weight: 600;
  text-transform: capitalize !important;
  color: ${(props) => props.theme.colors.white} !important;
  display: flex;
  height: 20px;
  padding: 8px 12px 3px;
  background: ${(props) => props.theme.colors.white5} !important;
  color: ${(props) => props.theme.colors.black3} !important;
  border-radius: 0px;
`;

const ProgramSchedule = styled.div`
  width: 100%;
  margin-top: 10px;
  text-align: left;
  box-sizing: border-box;
  h3 {
    text-decoration: underline;
    letter-spacing: 4px;
    margin-bottom: 10px;
  }
`;

const ScheduleHeading = styled.span`
  font-size: 14px;
  font-weight: 600;
  text-decoration: underline;
  margin-bottom: 20px;
  position: relative;
  text-align: left;
  top: 10px;
  color: ${(props) => props.theme.colors.black4};
  margin-top: 20px;
`;

const ScheduleBox = styled.ul`
  margin: 23px 0px;
  padding: 0px;
  background: ${(props) => props.theme.colors.white5};
  border-left: 3px solid ${(props) => props.color};
  box-sizing: border-box;
  li {
    padding: 20px;
    box-sizing: border-box;
    list-style: none;
    margin: 0px;
    font-size: 15px;
    &:nth-child(odd) {
      background: ${(props) => props.theme.colors.white22};
    }
  }
`;

const Program = styled.div`
  background: ${(props) => props.background};
  color: ${(props) => props.theme.colors.black};
  border-radius: 0px;
  padding: 20px 40px 0px;
  border-radius: 0px;
  min-width: 480px;
  max-width: 480px;
  float: left;
  text-align: left;
  flex: 2;
  &.inModal {
    margin-top: 0px !important;
  }
  .buttons {
    margin-top: 0px;
    display: flex;
    flex-wrap: wrap;
    margin-top: -30px;
    margin-left: -28px;
    ${down('sm')} {
      margin-top: -25px;
    }
    button {
      width: fit-content;
      margin-left: 5px;
      &.faq-button {
        &:hover {
          background: ${(props) => props.theme.colors.black};
          color: ${(props) => props.theme.colors.white};
        }
      }
    }
  }
  ${down('lg')} {
    max-width: initial;
    min-width: initial;
    width: available;
    margin-top: 20px;
    padding-right: 0px;
    width: -moz-available;
    width: -webkit-fill-available;
  }
  ${down('md')} {
    margin-left: 0px !important;
  }
  ${down('sm')} {
    padding: 30px 30px 20px 45px;
    margin-top: 20px;

    &.inModal {
      box-sizing: border-box;
      margin-left: 0px;
      padding-left: 30px;
    }
  }
  button {
    ${down('sm')} {
      margin-left: -5px;
    }
  }
  .secondary {
    letter-spacing: 1px;
    font-weight: 400;
    justify-content: left;
    text-align: left;
    box-sizing: border-box;
    ${down('sm')} {
      width: 100%;
    }
  }
  &.inModal {
    height: initial;
    margin-left: 15px;
  }
`;

const ProgramContent = styled.ul`
  padding-left: 0px;
  margin-bottom: 30px;
  margin-left: 30px;
  max-width: 95%;
  z-index: 2;
  position: relative;
  margin-left: 10px;
  &.inModal {
    max-width: 100%;
  }
  ${down('md')} {
    max-width: 100%;
  }
  li {
    font-size: 14px;
    text-indent: -18px;
    font-size: 14px;
    list-style: outside none;
    text-indent: -18px;
    line-height: 21px;
    padding: 20px;
    padding-left: 40px;
    margin: -40px;
    margin-bottom: 43px !important;
    width: 98%;
    background: ${(props) => props.theme.colors.white5};
    position: relative;
    &:hover {
      background-color: ${(props) => props.color};
      color: ${(props) => props.theme.colors.white};
      ::before {
        color: ${(props) => props.theme.colors.white};
      }
    }
    &:nth-of-type(2) {
      background: none;
      font-weight: 600;
      text-decoration: underline;
      font-family: pt sans;
      &:hover {
        color: ${(props) => props.theme.colors.black} !important;
      }
      ::before {
        display: none;
      }
    }
    &:first-child {
      background: none;
      max-width: 675px;
      font-size: 15px;
      padding: 0px 20px 10px 0px;
      width: 100%;
      text-align: left;
      text-indent: 0px;
      margin: 45px 0px 30px -20px !important;
      top: -10px;
      &:hover {
        color: ${(props) => props.theme.colors.black};
      }
      ::before {
        display: none;
      }
    }
  }
  li::before {
    font-size: 1.5em;
    padding-right: 10px;
    position: relative;
    display: inline;
    top: 3px;
    content: '•';
  }
`;

const ProgramTitle = styled.h3`
  color: ${(props) => props.color} !important;
  font-size: 23px;
  font-weight: 600;
  z-index: 5;
  margin-top: 5px;
  position: relative;
  letter-spacing: 1px;
  margin-left: -10px;
  &:before {
    content: '';
    background-image: url('/images/rings.png');
    background-size: contain;
    background-repeat: no-repeat;
    float: left;
    height: 50px;
    width: 60px;
    display: block;
    position: absolute;
    margin-top: -10px;
    margin-left: -20px;
  }
`;

const Card = styled.div`
  display: flex;
  width: 100%;
  flex-flow: initial;
  justify-content: center;
  @media (max-width: 700px) {
    flex-direction: column-reverse;
  }
`;

export default function ProgramCard(props) {
  const { program } = props;

  return (
    <Card className="Card">
      <Stream className="Stream">
        <StreamImage
          className="StreamImage"
          alt={program.title}
          src={program.image}
        />
        <ScheduleHeading />
        <Tags>
          {program.tags
            ? program.tags.split(', ').map((tag, indx) => (
                // eslint-disable-next-line react/no-array-index-key
                <ProgramTag key={indx} tag={tag}>
                  {tag}
                </ProgramTag>
              ))
            : null}
        </Tags>
        <ProgramSchedule>
          <ScheduleHeading>
            {program.schedulelabel ? program.schedulelabel : null}
          </ScheduleHeading>
          <ScheduleBox color={program.color2}>
            {program.schedulelabel
              ? program.schedule.split(', ').map((schedule, indx) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <li key={indx}>{schedule}</li>
                ))
              : null}
          </ScheduleBox>
        </ProgramSchedule>
      </Stream>
      <Program
        color1={program.color1}
        color2={program.color2}
        className="Program"
      >
        <ProgramTitle color={program.color1}>{program.title}</ProgramTitle>
        <ProgramContent
          color={program.color1}
          // image={program.image}
        >
          <li>{program.description}</li>
          <li>{program.bulletheading}</li>
          <li>{program.bullet1}</li>
          <li>{program.bullet2}</li>
          <li>{program.bullet3}</li>
          {program.bullet4 ? (
            <li color={program.color1}>{program.bullet4}</li>
          ) : null}
          {program.bullet5 ? (
            <li color={program.color1}>{program.bullet5}</li>
          ) : null}
          {program.bullet6 ? (
            <li color={program.color1}>{program.bullet6}</li>
          ) : null}
        </ProgramContent>
        <div className="buttons">
          <ModalButton
            border="none"
            background={program.color1}
            color={program.color1}
            float="left"
            text={program.buttoncopy}
            // program={program.title}
            modalcontent={
              <iframe
                title="iframe"
                src={`${program.buttonlink}?embedded=true`}
              />
            }
          />
          <Button
            textcolor={theme.colors.black}
            background={theme.colors.white}
            className="outline"
            text="FAQs"
            link={program.faqlink}
          />
        </div>
      </Program>
    </Card>
  );
}
