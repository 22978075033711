import { useContext } from 'react';
import Fade from 'react-reveal/Fade';
import withReveal from 'react-reveal/withReveal';
import Timeline from '@mui/lab/Timeline';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import { Typography } from '@mui/material';
import AppContext from 'AppContext';
import { down } from 'styled-breakpoints';
import styled from 'styled-components';
import TimelineCard from 'components/utilities/components/TimelineCard';

const ShiftedContent = styled.div`
  margin-top: -70px;
  margin-bottom: 60px;
`;

const Timelines = withReveal(
  styled(Timeline)`
    margin-top: 55px;

    .tag {
      font-size: 12px;
      font-weight: 500;
      background: ${(props) => props.theme.colors.black5};
      color: ${(props) => props.theme.colors.white};
      border-radius: 3px;
      margin-top: 3px;
      padding: 3px 6px;
      display: inline-block;
      font-weight: 600;
      position: relative;
      top: -2px;
      ${down('md')} {
        margin-left: 0px;
        margin-top: 8px;
      }
      &.label {
        background: ${(props) => props.theme.colors.white5};
        font-size: 14px;
        border-radius: 1px;
        margin-top: 8px;
        margin-left: -5px;
        display: block;
        color: ${(props) => props.theme.colors.black5};
        padding-top: 2px;
        padding-left: 12px;
        width: initial;
      }
    }
    .right {
      text-align: right;
    }
    li:last-of-type {
      .MuiTimelineConnector-root {
        display: none;
      }
    }
  `,
  <Fade fraction={0.1} />,
);

export default function CustomizedTimeline(props) {
  const { content } = useContext(AppContext);
  const { pageName } = props;
  const timelineObject = content[pageName].Timeline;

  const cardNumber =
    timelineObject && parseInt(Object.keys(timelineObject).length / 6, 10);

  const timelineCards = [];

  for (let index = 1; index <= cardNumber; index++) {
    if (!timelineObject[`event${index}`]) break;
    timelineCards.push({
      meta: timelineObject[`meta${index}`],
      title: timelineObject[`title${index}`],
      tag: timelineObject[`tag${index}`],
      description: timelineObject[`description${index}`],
      duration: timelineObject[`duration${index}`],
      icon: timelineObject[`icon${index}`],
      date: timelineObject[`date${index}`],
      event: timelineObject[`event${index}`],
    });
  }

  return (
    <Timelines id="Timeline" position="alternate">
      {timelineCards?.map((item, idx) => (
        // eslint-disable-next-line react/no-array-index-key
        <TimelineItem key={idx}>
          <TimelineOppositeContent>
            <Typography variant="body2" color="textSecondary">
              {item.date}
            </Typography>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot sx={{ bgcolor: 'black', padding: '3px' }}>
              {/* <LaptopMacIcon /> */}
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <ShiftedContent>
              <TimelineCard item={item} />
            </ShiftedContent>
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timelines>
  );
}
