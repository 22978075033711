import { useState } from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import { UtilButton } from './Button';
import { Tag } from './SingleTag';

// TODO: FIx me

export const Close = styled(Tag)`
  position: fixed;
  top: 10px;
  right: 15px;
  color: ${(props) => props.theme.colors.black};
  font-weight: 600;
  z-index: 1000;
  position: fixed;
  border-radius: 0px;
  background: ${(props) => props.theme.colors.purple3} !important;
  color: ${(props) => props.theme.colors.white} !important;
  &:hover {
    cursor: pointer;
    background: ${(props) => props.theme.colors.white14} !important;
  }
`;
const customStyles = {
  overlay: { zIndex: 100 },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

export function ModalButton(props) {
  const [isModalOpen, setModalOpen] = useState(false);

  function closeModal() {
    setModalOpen(false);
  }

  function onCloseButtonClick() {
    closeModal();
  }

  function openModal(e) {
    e.preventDefault();

    setModalOpen(true);
  }

  return (
    <>
      <UtilButton
        {...props}
        disabled={props.disabled}
        onClick={(e) => openModal(e)}
      >
        {props.text}
      </UtilButton>
      <Modal
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={false}
        ariaHideApp={false}
        isOpen={isModalOpen}
        onRequestClose={() => closeModal()}
        style={customStyles}
      >
        <Close onClick={() => onCloseButtonClick()}>Return to Website</Close>
        {props.modalcontent}
      </Modal>
    </>
  );
}
